@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("/fonts/HelveticaNeueLTPro-Th.eot");
  src: url("/fonts/HelveticaNeueLTPro-Th.eot?#iefix") format("embedded-opentype"), url("/fonts/HelveticaNeueLTPro-Th.woff2") format("woff2"), url("/fonts/HelveticaNeueLTPro-Th.woff") format("woff"), url("/fonts/HelveticaNeueLTPro-Th.ttf") format("truetype"), url("/fonts/HelveticaNeueLTPro-Th.svg#HelveticaNeueLTPro-Th") format("svg");
  font-weight: 300;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("/fonts/HelveticaNeueLTPro-Lt.eot");
  src: url("/fonts/HelveticaNeueLTPro-Lt.eot?#iefix") format("embedded-opentype"), url("/fonts/HelveticaNeueLTPro-Lt.woff2") format("woff2"), url("/fonts/HelveticaNeueLTPro-Lt.woff") format("woff"), url("/fonts/HelveticaNeueLTPro-Lt.ttf") format("truetype"), url("/fonts/HelveticaNeueLTPro-Lt.svg#HelveticaNeueLTPro-Lt") format("svg");
  font-weight: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("/fonts/HelveticaNeueLTPro-Bd.eot");
  src: url("/fonts/HelveticaNeueLTPro-Bd.eot?#iefix") format("embedded-opentype"), url("/fonts/HelveticaNeueLTPro-Bd.woff2") format("woff2"), url("/fonts/HelveticaNeueLTPro-Bd.woff") format("woff"), url("/fonts/HelveticaNeueLTPro-Bd.ttf") format("truetype"), url("/fonts/HelveticaNeueLTPro-Bd.svg#HelveticaNeueLTPro-Bd") format("svg");
  font-weight: bold;
}

* {
  font-size: 1.1rem;
  line-height: 1.25;
  font-family: "Helvetica Neue LT Pro";
  font-weight: 400;
}
@media (min-width: 576px) {
  *{
  font-size: 1.25rem;
  }
}
@media (min-width: 1200px) { 
  *{
    font-size: 1.5rem;
  }
}

body {
  background-color: #A31781;
  color: rgba(255,255,255,0.9);
  margin: 0;
}
h2,h5{
  letter-spacing: -0.08em;
  text-transform: lowercase;
  color: rgba(255,255,255,1);
  font-weight:700;
}
h2{
  font-size:1.5em;
}
h5{
  font-size:1em;
}
.row-untouched{
  background-color: rgba(255,255,255,0.1);
}

.bg-magenta {
  background-color: #A31781;
}